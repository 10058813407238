
import { windowCheck } from 'utils/helpers';

import Link from 'components/link/link';

import './styles/more-industries.scss';

type MoreIndustriesProps = {
  industries: {
    icon: {
      alt_text?: string,
      source_url: string,
    },
    title: string,
    url: string
  }[],
  showAll?: boolean,
}

const MoreIndustries  = ({ industries, showAll }:MoreIndustriesProps) => {
  const industriesList = showAll ? industries : industries.slice(0, 16);

  return (
    <section className="section more-industries-section" data-section="industry select">
      <div className="container-large">
        <div className="more-wrapper">
          <h2 className="title has-text-centered">
            Interested in other industries?
          </h2>
          <ul className="more-list">
            {industriesList.map((industry, index) => (
              <li key={ index } className="more-list-item">
                <Link
                  className={ `more-list-link ${windowCheck && window.location.pathname.includes(industry.url) ? 'active' : ''} ` }
                  to={ industry.url }
                >
                  {industry.icon?.source_url && (
                    <img
                      className="more-list-icon"
                      loading="lazy"
                      src={ industry.icon?.source_url }
                      alt={ industry.icon.alt_text }
                    />
                  )}
                  <p className="more-list-title">{industry.title}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        { !showAll && <Link className="view-all-link" to="/industries">View All Industries</Link> }
      </div>
    </section>
  );
};

export default MoreIndustries;
