import ACFSignup from 'types/ACFSignup';

import Signups from 'components/modal/signups';


type HeroIndustryProps = {
  copy: string
  signup: ACFSignup,
  sub_title: string,
  title: string,
  trial_length: number,
  uniqueId: string
}

export const HeroIndustry = ({
  copy,
  signup,
  sub_title,
  trial_length,
  title,
}:HeroIndustryProps) => {

  return (
    <section
      className="section hero-section"
      data-section="industryHero"
    >
      <div className="container container-large">
        <div className="hero-wrapper is-flex-desktop">
          <header>
            <h1 className="subtitle">{sub_title}</h1>
            <h2 className="title">{title}</h2>
            {copy && <p className="lead">{copy}</p>}
          </header>
          <div className="signup-wrapper">
            {signup.signup_cta_title && (
              <p className="title">{signup.signup_cta_title}</p>
            )}
            <Signups
              uniqueId={ signup.uniqueId }
              longPlaceholder={ true }
              legalText={ signup.legal_text }
              showInitFields={ true }
              fieldLayout={ signup.layout }
              signupModalSubmit={ signup.modal_submit_button_text }
              signupModalLaunch={ signup.launch_modal_button_text }
              trialLength={ trial_length }
            />
          </div>
        </div>
      </div>
    </section>
  ); 
};